import React from 'react'
import { FormattedMessage } from 'react-intl'
import Loadable from 'react-loadable'
import Link from './localized-link'
import placeholderImage from '../assets/img/png/placeholder-lg.png'
import _ from 'lodash'
import H3 from '../theming/H3'
import { useHeaderImage } from './property/image'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { PropertyPrice } from './property/property-price'
import { ProjectPrice } from './property/project-price'
import { isPropertyInRegionWithPEB } from '../utils/helpers'
import { TransChoice } from './trans-choice'

// we use React Loadable here
// not for performance purposes
// but to avoid the dates from SSR
// to  be shown wich can be outdated or
// in a different timezone
const OpenHomeLabel = Loadable({
  loader: () => import('./property/oh-label'),
  loading: () => null,
})

const ProperyCard = ({ property = {}, intl, style }) => {
  const posterImage = useHeaderImage({ property })

  return (
    <Link
      to={`/${getPropertySection(property)}/${property.id}`}
      className="property property--link"
      style={style}
      onClick={() => {
        /* eslint-disable-next-line */
        window.analytics?.track('Property Card Clicked', {
          propertyId: property.id,
        })
      }}
    >
      <div
        className={classNames('property__image bg-white', {
          'property__image--panorama': posterImage?.equirectangular,
        })}
        style={{
          backgroundImage: `url("${
            posterImage?.url || placeholderImage
          }"), url("${placeholderImage}")`,
        }}
      >
        <OpenHomeLabel property={property} />
      </div>
      <EnergyEfficiencyLogo property={property} />
      <div className="property__info">
        <span className="property__type">{getNegotiation(property, intl)}</span>
        <H3
          className="property__title"
          title={_.get(property, 'attributes.description.title.' + intl.locale)}
        >
          {_.get(property, 'attributes.description.title.' + intl.locale)}
        </H3>
        <Description property={property} />
      </div>
    </Link>
  )
}

export default React.memo(injectIntl(ProperyCard))

function Description({ property }) {
  if (property.internal_type === 'project') {
    return (
      <span className="property__description truncate">
        <span className="units">
          {property.properties?.length ?? 0}
          {` `}
          <TransChoice
            id="project.units.label"
            count={property.properties?.length ?? 0}
          />
          <span className="propertyPrice"> – </span>
        </span>
        <ProjectPrice project={property} />
      </span>
    )
  }

  return (
    <div className="property__description truncate">
      {['house', 'apartment'].includes(property.type) && (
        <>
          <Bedrooms property={property} />
          {' - '}
        </>
      )}
      <PropertyPrice property={property} />
    </div>
  )
}

function EnergyEfficiencyLogo({ property }) {
  if (!isPropertyInRegionWithPEB(property)) {
    return null
  }

  return (
    <div style={{ top: '0.5rem', right: '0.5rem', position: 'absolute' }}>
      <PEBLogo property={property} />
    </div>
  )
}

const pebImages = {
  'A++': 'peb_aplusplus.png',
  'A+': 'peb_aplus.png',
  A: 'peb_a.png',
  B: 'peb_b.png',
  C: 'peb_c.png',
  D: 'peb_d.png',
  E: 'peb_e.png',
  F: 'peb_f.png',
  G: 'peb_g.png',
}

function PEBLogo({ property }) {
  const epcCategory = property.attributes.legal?.energy?.epc_category

  if (!epcCategory) {
    return null
  }

  return (
    <img
      src={`/img/peb_logos/${pebImages[epcCategory]}`}
      alt={epcCategory}
      style={{ display: 'block', height: 20, width: 'auto' }}
    />
  )
}

function getPropertySection(property) {
  if (property.internal_type === 'project') {
    return 'projects'
  }
  return property.negotiation
}

const Bedrooms = ({ property }) => {
  const count = property.attributes.structure?.bedrooms ?? 0

  return (
    <>
      {count} <TransChoice count={count} id="plurals.bedrooms" />
    </>
  )
}

function getNegotiation(property, intl) {
  const negotiation = intl.formatMessage({ id: property.negotiation })
  const status = property.attributes.status

  const location = getLocation(property)
  return (
    <div className="flex">
      <span className="truncate">
        {location ? (
          <FormattedMessage id="in">
            {(message) => {
              return (
                <span title={`${negotiation} ${message} ${location}`}>
                  {negotiation} {message} {location}
                </span>
              )
            }}
          </FormattedMessage>
        ) : (
          negotiation
        )}
      </span>
      {['sold', 'rented'].includes(status) && <> &ndash; </>}
      {['sold', 'rented'].includes(status) && (
        <div sytle={{ flexShrink: 0 }}>
          <span className="property__status">
            <FormattedMessage id={`property.status.${status}`} />
          </span>
        </div>
      )}
    </div>
  )
}

function getLocation(property) {
  const city = _.get(property, 'attributes.location.city')
  const district = _.get(property, 'attributes.location.borough_or_district')
  return city || district
}
