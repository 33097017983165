import React from 'react'
import { useAgency } from '../../hooks/use-agency'
import { FormattedMessage } from 'react-intl'
import { formatMoneyAmount } from '../../utils/helpers'

export function PropertyPrice({ property }) {
  const { settings } = useAgency()
  const { negotiation } = property
  const price = property.attributes.price?.published_price

  if (!property.publish_price || !property.attributes.price) {
    return <FormattedMessage id="price-on-request" />
  }

  return (
    <span>
      {formatMoneyAmount(price)}
      {negotiation === 'let' && ' / '}
      {negotiation === 'let' ? (
        <FormattedMessage id={settings.rent_period} />
      ) : null}
    </span>
  )
}
